import { HistoryLocation } from '@reach/router'
import React, { ReactElement } from 'react'

import PageModule from './PageModule'

export type PageModuleArrayProps = DBN.IReactDefaultProps & {
  location: HistoryLocation
  modulearray: Array<DBN.Contentful.PageModuleUnion>
  pageContext: DBN.PageHelpers.PageContext
}

export default function PageModuleArray({
  className,
  location,
  pageContext,
  modulearray,
}: PageModuleArrayProps): ReactElement {
  return (
    <>
      {modulearray.map((mod, i) => (
        <PageModule
          key={`${mod.id}${i}`}
          className={className}
          location={location}
          pagemodule={mod}
          pageContext={pageContext}
        />
      ))}
    </>
  )
}
