import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'
import PageModuleArray from '@system/utils/PageModuleArray'
import { HistoryLocation } from '@reach/router'

export interface CasePageProps {
  children?: typeof React.Children
  location: HistoryLocation
  pageContext: DBN.PageHelpers.PageContext
  data: {
    contentfulCasePage: {
      modules: Array<DBN.Contentful.PageModuleUnion>
    }
  }
}

export default function CasePage({
  data,
  location,
  pageContext,
}: CasePageProps): ReactElement {
  const modules = data.contentfulCasePage.modules
  return (
    <>
      {!!modules && (
        <PageModuleArray
          modulearray={modules}
          location={location}
          pageContext={pageContext}
        />
      )}
    </>
  )
}

export const pageQuery = graphql`
  query CasePageByPath($id: String!, $locale: String!) {
    contentfulCasePage(id: { eq: $id }, node_locale: { eq: $locale }) {
      modules {
        ...ContentfulModulesFragment
      }
    }
  }
`
